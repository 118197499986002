import React from 'react';

import {
    HeroSectionFeatured as HeroSectionFeaturedDS,
    HeroSectionFeaturedProps,
} from '@solublestudio/legalbrave-design-system';

const HeroSection = (props: HeroSectionFeaturedProps) => {
    return <HeroSectionFeaturedDS {...props} />;
};

export default HeroSection;
